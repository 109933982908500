@import './custom-antd.css';

// primary_color: 4fd15a

.container {
  max-width: 1920px;
  margin: 0 auto;
  p {
    margin: 0;
  }
}
.ant-btn-sm {
  height: 30px !important;
}

.callback-payment {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 5rem;
  background: #FAFAFA;
  & > div {
    padding: 2rem;
    border: 1px solid rgb(221, 221, 221);
    border-bottom: 4px solid rgb(82, 196, 26);
    background: #fff;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
    img {
      margin: 0 auto;
      display: block;
    }
  }
}

@import './responsive.scss'